import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {
  Reservation,
  Reservee,
  Status,
} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationsService} from '../../../services/reservationsService'
import {ApiError, isWixHttpError} from '../../../utils/errors'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation, navigateToCheckoutPage} from '../../../utils/navigation'
import {getLogger} from '../../../utils/getLogger'
import {checkoutService} from '../../../services/checkoutService'
import {isFreeOrder} from '../../../utils/ecom'

export enum ReserveReservationApplicationErrorCode {
  HELD_EXPIRED = 'HELD_EXPIRED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
}

export interface ReserveReservationApiError
  extends ApiError<ReserveReservationApplicationErrorCode> {}

export const reserveReservation = (flowAPI: ControllerFlowAPI) => {
  return async (
    reservation: Reservation,
    reservee: Reservee,
    reservationLocation: ReservationLocation,
  ) => {
    const setProps = flowAPI.controllerConfig.setProps
    const logger = getLogger(flowAPI.bi!)

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.reserveReservation(
        flowAPI,
        reservation.id ?? '',
        reservee,
        reservation.revision ?? '',
      )

      // this is the only place where can get just created reservation
      if (finishedReservation?.id) {
        logger.reservationCreated({
          isPreview: false,
          reservation: finishedReservation!,
          reservee,
          reservationLocation,
        })

        const checkoutResponse = await checkoutService.createCheckout(
          flowAPI,
          finishedReservation.id,
          reservee,
        )

        if (!checkoutResponse?.checkout) {
          throw new Error('Checkout is undefined')
        }

        const isFreeReservation = isFreeOrder(checkoutResponse.checkout)

        if (isFreeReservation) {
          try {
            await checkoutService.createOrderFromCheckout(flowAPI, checkoutResponse.checkout!.id!)
          } catch (error) {
            if (
              finishedReservation.status === Status.RESERVED ||
              finishedReservation.status === Status.REQUESTED
            ) {
              console.error('Error creating order from checkout', error)
            } else {
              throw error
            }
          }
          await goToReservationConfirmation(flowAPI, finishedReservation.id)
        } else {
          await navigateToCheckoutPage(
            flowAPI,
            checkoutResponse.checkout!.id!,
            finishedReservation.id,
          )
        }
      } else {
        throw new Error('No reservation')
      }
    } catch (err: any) {
      const apiError: ReserveReservationApiError = {}

      const applicationErrorCode = err?.response?.data?.details?.applicationError?.code

      if (isWixHttpError(err) && applicationErrorCode) {
        apiError.applicationCode = applicationErrorCode
        apiError.message = err?.response?.data?.message
      } else {
        apiError.message = err.message
        apiError.applicationCode =
          err?.request?.status === 429
            ? ReserveReservationApplicationErrorCode.TOO_MANY_REQUESTS
            : undefined
      }

      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: apiError,
      })
    }
  }
}
